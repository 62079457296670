"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVideo = void 0;
const react_1 = require("react");
const CompositionManager_1 = require("./CompositionManager");
const useVideo = () => {
    const context = (0, react_1.useContext)(CompositionManager_1.CompositionManager);
    return (0, react_1.useMemo)(() => {
        var _a;
        const selected = context.compositions.find((c) => {
            return c.id === context.currentComposition;
        });
        if (selected) {
            return {
                ...selected,
                // We override the selected metadata with the metadata that was passed to renderMedia(),
                // and don't allow it to be changed during render anymore
                ...((_a = context.currentCompositionMetadata) !== null && _a !== void 0 ? _a : {}),
            };
        }
        return null;
    }, [
        context.compositions,
        context.currentComposition,
        context.currentCompositionMetadata,
    ]);
};
exports.useVideo = useVideo;
