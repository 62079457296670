"use strict";
exports.__esModule = true;
exports.NAMESPACES = exports.BUTTON_LABELS = exports.DEFAULT_OPTIONS = void 0;
exports.DEFAULT_OPTIONS = {
    aspectRatio: 1.7,
    disableLogs: true,
    fileName: String(new Date().getTime()),
    height: 720,
    mimeType: 'video/webm',
    type: 'video',
    width: 1280
};
exports.BUTTON_LABELS = {
    OPEN: 'Open camera',
    CLOSE: 'Close camera',
    START: 'Start recording',
    STOP: 'Stop recording',
    RETAKE: 'Retake recording',
    DOWNLOAD: 'Download recording'
};
exports.NAMESPACES = {
    CSS: 'react-record-webcam'
};
