"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prefetch = exports.usePreload = void 0;
const react_1 = require("react");
const get_environment_1 = require("./get-environment");
const prefetch_state_1 = require("./prefetch-state");
const usePreload = (src) => {
    var _a;
    const preloads = (0, react_1.useContext)(prefetch_state_1.PreloadContext);
    return (_a = preloads[src]) !== null && _a !== void 0 ? _a : src;
};
exports.usePreload = usePreload;
const blobToBase64 = function (blob) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = function () {
            const dataUrl = reader.result;
            resolve(dataUrl);
        };
        reader.onerror = (err) => {
            return reject(err);
        };
        reader.readAsDataURL(blob);
    });
};
const prefetch = (src, options) => {
    var _a;
    const method = (_a = options === null || options === void 0 ? void 0 : options.method) !== null && _a !== void 0 ? _a : 'blob-url';
    if ((0, get_environment_1.getRemotionEnvironment)() === 'rendering') {
        return {
            free: () => undefined,
            waitUntilDone: () => Promise.resolve(src),
        };
    }
    let canceled = false;
    let objectUrl = null;
    let resolve = () => undefined;
    let reject = () => undefined;
    const waitUntilDone = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    const controller = new AbortController();
    let canBeAborted = true;
    fetch(src, {
        signal: controller.signal,
    })
        .then((res) => {
        canBeAborted = false;
        if (canceled) {
            return null;
        }
        if (!res.ok) {
            throw new Error(`HTTP error, status = ${res.status}`);
        }
        return res.blob();
    })
        .then((buf) => {
        if (!buf) {
            return;
        }
        if (method === 'base64') {
            return blobToBase64(buf);
        }
        return URL.createObjectURL(buf);
    })
        .then((url) => {
        if (canceled) {
            return;
        }
        objectUrl = url;
        (0, prefetch_state_1.setPreloads)((p) => ({
            ...p,
            [src]: objectUrl,
        }));
        resolve(objectUrl);
    })
        .catch((err) => {
        reject(err);
    });
    return {
        free: () => {
            if (objectUrl) {
                if (method === 'blob-url') {
                    URL.revokeObjectURL(objectUrl);
                }
                (0, prefetch_state_1.setPreloads)((p) => {
                    const copy = { ...p };
                    delete copy[src];
                    return copy;
                });
            }
            else {
                canceled = true;
                if (canBeAborted) {
                    try {
                        controller.abort();
                    }
                    catch (e) { }
                }
            }
        },
        waitUntilDone: () => {
            return waitUntilDone;
        },
    };
};
exports.prefetch = prefetch;
