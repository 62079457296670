"use strict";
/**
 * The configuration has moved to @remotion/cli.
 * For the moment the type definitions are going to stay here
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.enableLegacyRemotionConfig = exports.Config = void 0;
const conf = {};
let enabled = false;
exports.Config = new Proxy(conf, {
    get(target, prop, receiver) {
        if (!enabled) {
            throw new Error('To use the Remotion config file, you need to have @remotion/cli installed.\n- Make sure that all versions of Remotion are the same.\n- Make sure that @remotion/cli is installed.\n- Make sure Config is imported from "@remotion/cli", not "remotion".');
        }
        return Reflect.get(target, prop, receiver);
    },
});
const enableLegacyRemotionConfig = () => {
    enabled = true;
};
exports.enableLegacyRemotionConfig = enableLegacyRemotionConfig;
