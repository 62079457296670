"use strict";
exports.__esModule = true;
exports.saveFile = void 0;
exports.saveFile = function (filename, blob) {
    if (window.navigator.msSaveOrOpenBlob !== undefined) {
        window.navigator.msSaveBlob(blob, filename);
        return;
    }
    var elem = window.document.createElement('a');
    elem.style.display = 'none';
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
};
