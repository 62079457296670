"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaTagVolume = void 0;
const react_1 = require("react");
// Returns the real volume of the audio or video while playing,
// no matter what the supposed volume should be
const useMediaTagVolume = (mediaRef) => {
    const [actualVolume, setActualVolume] = (0, react_1.useState)(1);
    (0, react_1.useEffect)(() => {
        const ref = mediaRef.current;
        if (!ref) {
            return;
        }
        const onChange = () => {
            setActualVolume(ref.volume);
        };
        ref.addEventListener('volumechange', onChange);
        return () => ref.removeEventListener('volumechange', onChange);
    }, [mediaRef]);
    (0, react_1.useEffect)(() => {
        const ref = mediaRef.current;
        if (!ref) {
            return;
        }
        if (ref.volume !== actualVolume) {
            setActualVolume(ref.volume);
        }
    }, [actualVolume, mediaRef]);
    return actualVolume;
};
exports.useMediaTagVolume = useMediaTagVolume;
